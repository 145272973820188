.sidebar_logo {
  padding: 25px 15px 32px 15px;
  display: flex;
  justify-content: center;
}
.sidebar_logo img {
  height: 19px;
  margin-top: 1px;
}
.sidebar_logo h6 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  padding-left: 3px;
}
.header h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 10px 0px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0px;
}
.menu_button {
  font-size: 15px;
  font-weight: 500;
  color: #2b2b2b;
  padding: 0px 20px 0px 0px;
}
.menu_button:hover {
  color: #2b2b2b;
}

.ant-menu-title-content a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown .ant-dropdown-menu {
  margin-right: 20px;
}
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: #626ed4;
}
.ant-menu-dark .ant-menu-item-selected {
  background-color: #626ed4;
}
.ant-menu-item .ant-menu-item-active .ant-menu-item-only-child {
  background-color: #626ed4;
}
.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
  border-color: #626ed4;
}
.ant-btn-primary {
  background-color: #626ed4;
}
.css-dev-only-do-not-override-w8mnev
  .ant-pagination
  .ant-pagination-item-active
  a {
  color: #626ed4;
}
.css-dev-only-do-not-override-w8mnev
  .ant-pagination
  .ant-pagination-item-active {
  border-color: #626ed4;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #001529;
  border-color: #001529;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner
  .ant-checkbox:not(.ant-checkbox-disabled):hover
  .ant-checkbox-inner {
  border-color: #001529;
}
.ant-picker-calendar
  .ant-picker-calendar-header
  .ant-picker-calendar-mode-switch {
  display: none;
}
.ant-picker-calendar
  .ant-picker-calendar-header
  .ant-picker-calendar-month-select {
  display: none;
}
.ant-picker-calendar
  .ant-picker-calendar-header
  .ant-picker-calendar-year-select {
  display: none;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  text-align: center;
}
.ant-picker-calendar.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-body
  th {
  padding-left: 12px;
}
.ant-input {
  font-family: "Montserrat", sans-serif;
  color: #2b2b2b;
  font-weight: 500;
}

/* Events card container css */
.card_container {
  display: flex;
  flex-wrap: wrap;
}
.ant-card-meta-title {
  text-align: center;
}
.ant-card-meta-description {
  text-align: center;
}
.header p {
  font-weight: bold;
  padding: 10px 0px 15px 0px;
}
/* .ant-card .ant-card-body {
  padding: 0;
} */
.card_container .card_content {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0px 5px;
}
.card_container .card_content h3 {
  font-size: 16px;
  font-weight: 600;
  color: #626ed4;
}
.card_container .card_content h6 {
  font-size: 13px;
  font-weight: 600;
  color: #2b2b2b;
}
.card_container .card_content2 {
  padding: 0px 5px 10px 5px;
  text-align: right;
}
.card_container .card_content2 h6 {
  font-size: 12px;
  font-weight: 600;
  color: #2b2b2b;
}
/* ----- faq section css start here ----- */

.header {
  display: flex;
  justify-content: space-between;
}
.header .header-search {
  padding-bottom: 20px;
}
.header .header-search input {
  padding: 5px 10px 5px 5px;
}
.header .header-search .ant-input-prefix {
  display: none;
}
.header button {
  padding: 8px 18px;
  color: #fff;
  background-color: #626ed4;
  border: none;
  font-size: 14px;
  font-weight: 500;
}
.header4 {
  display: flex;
  justify-content: space-between;
}
.header4 p {
  font-weight: bold;
  padding: 10px 0px 15px 0px;
}
.header4 .header-search {
  padding-bottom: 20px;
}
.header4 .header-search input {
  padding: 5px 10px 5px 5px;
}
.header4 .header-search .ant-input-prefix {
  display: none;
}
.header4 button {
  padding: 8px 18px;
  color: #fff;
  background-color: #626ed4;
  border: none;
  font-size: 14px;
  font-weight: 500;
}
.header4 h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 10px 0px;
}
.faq_content {
  padding-top: 30px;
}
.faq_content p {
  background-color: #fff;
  padding: 10px 0px;
}
.form_submit {
  display: flex;
  justify-content: flex-end;
}
.form_submit button {
  background-color: #626ed4;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 500;
}
.form_submit .back_button {
  background-color: transparent;
  border: 1px solid #ccc;
  color: rgba(0, 0, 0, 0.88);
  margin-right: 5px;
}
.form_submit .back_button:hover {
  color: rgba(0, 0, 0, 0.88) !important;
  border: 1px solid #ccc;
}

.form_container {
  width: 100%;
  padding-top: 30px;
}
.wrapper-class {
  border: 1px solid #ccc;
  min-height: 300px;
  padding: 0px 5px;
  overflow: auto;
}

.toolbar-class {
  border: 1px solid #ccc;
}
.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background-color: #626ed4;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #626ed4;
}
.ant-spin .ant-spin-dot-item {
  background-color: #626ed4;
}
.ant-pagination .ant-pagination-item-active {
  border-color: #626ed4;
}

.ant-pagination .ant-pagination-item-active a {
  color: #626ed4;
}
.ant-btn-default:not(:disabled):hover {
  color: #444;
}
.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  color: #fff;
}

.view_content {
  font-size: 14px;
  font-weight: 500;
}
/* ------dashboard start from here-------- */
.dashboard_card {
  padding: 0px 0px 30px 0px;
}
.event_content {
  padding: 30px 0px;
}
.spin_information {
  display: flex;
  justify-content: center;
}
.spin_information2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.spin-center2 {
  position: absolute;
  top: 50%;
}
.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description {
  color: #2b2b2b;
}
.ant-tabs .ant-tabs-tab:hover {
  color: #2b2b2b;
}

.ant-table-wrapper .ant-table-row-expand-icon:focus {
  color: #626ed4;
  border-color: #626ed4;
}
.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: #626ed4;
}
.ant-pagination .ant-pagination-item-active a:hover {
  color: #626ed4;
}
.ant-pagination .ant-pagination-item-active:hover {
  border-color: #626ed4;
}
.ant-modal .ant-modal-content {
  padding: 30px 30px;
}
.ant-modal .ant-modal-footer {
  margin-top: 25px;
}

.event_spin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* report page radio button */
.radio-py {
  padding-bottom: 10px;
}
.back-events {
  margin-left: 5px;
}
.gender-check {
  margin-bottom: 8px;
}
.gender-head {
  margin-bottom: 4px;
}
.gender-head h4 {
  font-weight: 600;
}
/* events card section css start here */
.card-image {
  height: 340px;
  object-fit: cover;
}
.card-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}
.card-name {
  text-align: left;
}
.card-name-new {
  text-align: left;
  margin-bottom: 4px;
}
.ant-card .ant-card-actions > li > span > .anticon {
  font-size: 18px;
}
.ant-card .ant-card-actions > li > span {
  font-size: 20px;
}
/* event no data */
.no-data {
  display: flex;
  justify-content: center;
}
.event-nodata {
  font-size: 14px;
  color: #777;
  font-weight: 500;
}

/* dashboard lastet events spin */
.spin-center-normal {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
.empty-box-padding {
  padding: 10px 0px 0px 0px;
}
/* small skeleton input size */
.ant-skeleton.ant-skeleton-element .ant-skeleton-input-sm {
  height: 14px !important;
  border-radius: 20px;
}

/* report button */
.header6 {
  display: flex;
  justify-content: space-between;
}
.header6 h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 10px 0px;
}
.report-button {
  display: flex;
  flex-direction: row;
}
.image-button1 {
  margin-right: 8px;
}
