/* user details css start here */
.user_profileimg {
  position: relative;
  margin: -80px 0px 0px 10px;
}
.user_profileimg .profile_img {
  border-radius: 100px;
  border: 5px solid #fff;
}
.background-img .user_profileimg .ant-image-mask {
  border-radius: 100px !important;
}
.user_profileimg h3 {
  margin: -50px 0px 0px 170px;
  font-size: 20px;
  font-weight: 600;
  color: #2b2b2b;
}
.background-img .ant-image-mask {
  border-radius: 0px !important;
}
.card-img-cover .ant-image-mask {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.card-img-cover .dashboard-card-profile .ant-image-mask {
  border-radius: 25px !important;
}