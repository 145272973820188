.dashboard_card {
  padding: 0px 0px 30px 0px;
}
.head_dashboard {
  padding: 0px 0px 30px 0px;
}

.head_dashboard h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
.head_events h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
.head_dashboard2 {
  padding: 0px 0px 0px 0px;
}

.head_dashboard2 h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
.ant-image .ant-image-mask {
  border-radius: 25px !important;
}
.ant-card .ant-card-meta-detail >div:not(:last-child)   { 
  margin-bottom: 0px !important;
}
