@import url("./homepage.css");
@import url("./login.css");
@import url("./form.css");
@import url("./dashboard.css");
@import url("./custom.css");
@import url("./users.css");
@import url("./media.css");
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

/* events calender css */
.events {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.add_event_calendar p {
  padding: 2px 0px;
  font-size: 14px;
  font-weight: 400;
}

.head_events h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
/* user details css */
.user_profile {
  position: absolute !important;
  bottom: 120px;
  left: 20px;
}
/* contact view */
.view_content {
  padding-top: 20px;
}
.view_content h2 {
  padding-bottom: 20px;
}
.view_content h4 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
}
.view_content h6 {
  font-weight: 400;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.88);
  padding-bottom: 20px;
}
